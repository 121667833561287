<template>
  <v-container v-if="hasPermission" fluid>
    <template>
      <v-row justify="center">
        <v-col cols="12">
          <mex-heading content="Clinic View Manually Managed" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <mex-sheet  color="background" rounded sheetPadding="2">
            <v-row class="justify-center">
              <v-col cols="4">
                <v-autocomplete
                  v-model="selectedClinic"
                  :items="clinicNames"
                  color="primaryAccent"
                  hide-details
                  label="Clinic Name"
                  outlined
                  dense
                  @change="selectionChanged"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      <v-list-item-subtitle class="ma-2">
                        {{ data.item.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </mex-sheet>
        </v-col>
      </v-row>
      <template v-if="selectedClinic">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <expansion-sheet
                  color="foreground"
                  rounded
                >
                  <template v-slot:header>
                    <v-row>
                      <v-col align-self="center" cols="8">
                        <mex-p
                          class="mb-0"
                          content="Upload Clinic Info"
                          fontSize="h5"
                          fontWeight="bold-italic"
                        />
                      </v-col>
                      <v-col align-self="center" cols="4">
                        <mex-btn
                          icon="mdi-arrow-right-bold-hexagon-outline"
                          content="Go to clinic view"
                          @click="goToClinicView"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:content>
                    <v-row>
                      <v-col cols="8">
                        <v-file-input label="File input"></v-file-input>
                      </v-col>
                      <v-col cols="4">
                        <mex-btn content="Save"></mex-btn>
                      </v-col>
                    </v-row>
                  </template>
                </expansion-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ClinicsService from "../../services/clinics.service";
import ExpansionSheet from "../../components/LicSrvComponents/ExpansionSheet.vue";
import requiredPermissions from "../../requiredPermissions";
import {assignPropertyIfExists} from "../../functions/assignPropertyIfExists";

export default {
  name: "ClinicView",
  components: {
    ExpansionSheet,
  },
  computed: {
    ...mapGetters("sysAuth", ["getUserPermissions"]),
  },
  data() {
    return {
      // data
      clinicNames: [],
      selectedClinic: "",
      dataUpload: null,
      // edit
      hasPermission: false,
    };
  },
  methods: {
    selectionChanged(value) {
      this.selectedClinic = value;
      this.$store.commit('selectedProperties/setClinicViewManuallyManaged', this.selectedClinic);
    },
    getSelectData() {
      ClinicsService.getCompressedClinicOverview(true)
        .then(clinicResponse => {
          clinicResponse.data.forEach(clinic => {
            if (clinic.managedManually) {
              this.clinicNames.push({
                value: clinic.ClinicID,
                text: `${clinic.name} / ${clinic.clinicUUID}`,
                description: clinic.Organization.name
              });
            }
          });
        })
        .catch(err => {
          this.$toast.error(err.response.data);
        });
    },
    goToClinicView() {
      this.$router.push({
        name: "ClinicView",
        params: { id: this.selectedClinic }
      });
    },
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.manuallyManagedClinics, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.getSelectData();
          this.selectedClinic = assignPropertyIfExists([this.$route.params, this.$route.query], 'id', this.$store, 'clinicViewManuallyManaged', 'setClinicViewManuallyManaged');
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      });
  },
};
</script>
<style scoped>
.stickyHeader {
  position: sticky;
  top: 51px;
  z-index: 10;
}
</style>
