<template>
  <mex-sheet v-bind="$attrs">
    <v-row justify="start">
      <v-col cols="11">
        <slot name="header"/>
      </v-col>
      <v-col cols="1" v-if="showArrow">
        <v-icon v-if="expand" @click="expand = !expand">mdi-chevron-up</v-icon>
        <v-icon v-else @click="expand = !expand">mdi-chevron-down</v-icon>
      </v-col>
    </v-row>
    <v-expand-transition>
      <template v-if="expand">
        <div>
          <slot name="content" />
        </div>
      </template>
    </v-expand-transition>
  </mex-sheet>
</template>

<script>
export default {
  name: 'ExpansionSheet',
  props: {
    startExpand: {
      type: Boolean,
      default: true,
    },
    showArrow: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      expand: false,
    };
  },
  created() {
    this.expand = this.startExpand;
  },
};
</script>

<style></style>
